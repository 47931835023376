window.addEventListener("DOMContentLoaded", ()=>{
  const menuList = document.querySelector('.menu__list');
  const buttons = menuList.querySelectorAll('[href^="#"]');

  buttons.forEach(btn=>{
      btn.addEventListener("click", (e)=>{
          e.preventDefault();
          const id = btn.href.replace(/[^#]*(.*)/, '$1');
          const element = document.getElementById(id.replace("#",""));
          // scrollTo.scrollIntoView({behavior: "smooth"});
          const y = element.getBoundingClientRect().top + window.pageYOffset + -80;
        window.scrollTo({top: y, behavior: "smooth"})

          menu_close();
      });
  });
});